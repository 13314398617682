<template>
  <secondary-nav-bar-content title="Kommune adresser">
    <p slot="description" class="text-gray-600 my-4">
      <span class="block">Eksporter addresser for den valgte kommune.</span>
      <base-alert
        title="INFO"
        type="info"
        class="my-4"
        :align-center="true"
        description="Adresserne bliver hentet fra Danmarks Adresse Register (DAWA)."
      ></base-alert>
    </p>

    <job-poller
      v-if="generateAddressesJobId"
      title="Genererer eksport.."
      description="Dette kan tage nogle minutter, hvis det er en stor kommune"
      :job-id="generateAddressesJobId"
      :type="jobTypes.DATAGENERATIONADDRESSES"
      @result="onResult"
    >
      <image-container v-if="downloadUrl" asset-name="success" class="mb-6">
        <p>Hvis filen ikke automatisk blev downloadet i din browser, kan du trykke på linket herunder</p>
        <a
          :href="downloadUrl"
          _target="blank"
          class="underline text-blue-500 cursor-pointer my-2"
        >Download fil</a>
      </image-container>
    </job-poller>

    <h3 class="text-gray-900 text-xl mt-4 mb-6 font-medium">Adresse eksport information</h3>

    <base-input label="Vælg kommune" class="mt-4">
      <base-select
        slot="input"
        v-model="form.administrativeAreaCode"
        width="w-72"
        :clearable="true"
        :options="administrativeAreaOptions"
        tabindex="1"
        placeholder="Vælg kommune"
      ></base-select>
    </base-input>
    <base-input class="mt-4 w-72" label="NorthArc kolonner" tooltip="Vil du kun have addresserne eller importere til NorthArc">
      <base-checkbox
        slot="input"
        v-model="form.withNorthArcColumns"
        tabindex="2"
      ></base-checkbox>
    </base-input>
    <base-button class="mt-4" tabindex="3" color="indigo" :disabled="!!generateAddressesJobId" @click="startExport">Eksporter adresser</base-button>
  </secondary-nav-bar-content>
</template>

<script>
import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';
import JobPoller from '@/components/JobPoller.vue';
import ImageContainer from '@/components/ImageContainer.vue';

import { mapActions, mapGetters } from 'vuex';
import * as jobTypes from '@/util/jobTypes';
import { EXPORT_ADMINISTRATIVE_AREAS, GET_ADMINISTRATIVE_AREAS } from '@/store/actions.type';

export default {
  name: 'MunicipalityAddressExport',
  components: {
    SecondaryNavBarContent,
    JobPoller,
    ImageContainer,
  },
  data() {
    return {
      jobTypes,
      downloadUrl: '',
      form: {
        administrativeAreaCode: '',
        withNorthArcColumns: false,
      },
    };
  },
  computed: {
    ...mapGetters('jobs', ['generateAddressesJobId']),
    ...mapGetters('datageneration', ['administrativeAreaOptions']),
  },
  async mounted() {
    try {
      this.getMunicipalities();
    } catch (error) {
      this.$unhandledError(error);
    }
  },
  methods: {
    ...mapActions('datageneration', {
      exportMunicipalityAddresses: EXPORT_ADMINISTRATIVE_AREAS,
      getMunicipalities: GET_ADMINISTRATIVE_AREAS,
    }),
    async startExport() {
      this.$ntf.info('Begynder eksport...');
      try {
        await this.exportMunicipalityAddresses(this.form);
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    onResult(downloadUrl) {
      this.$ntf.success('Din eksport er klar');
      this.downloadUrl = downloadUrl;
      window.open(downloadUrl, '_blank');
    },
  },
};
</script>
