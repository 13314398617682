<template>
  <secondary-nav-bar-content title="Eksport">
    <p slot="description" class="text-gray-600 my-4">
      <span class="block">Eksporter de nye planlægningsfelter sammen med det oprindelige datasæt.</span>
      <span
        class="block mt-0.5"
      >NorthArc benytter de felter, der blev valgt under import til at indsætte planlægningsfelterne under samme kolonnenavn.</span>
      <base-alert
        title="OBS"
        type="info"
        class="my-4"
        :align-center="true"
        description="I dokumentet vil northarcs planlægningskolonner være markeret med grønt, mens de indlæste planlægningskolonner vil være gule. De resterende kolonner bibeholder værdierne fra importeringen."
      ></base-alert>
    </p>

    <job-poller
      v-if="generateExternalExportJobId"
      title="Genererer eksport.."
      description="Dette kan tage nogle minutter, hvis det er et stort workspace"
      :job-id="generateExternalExportJobId"
      :type="jobTypes.EXPORT_EXTERNAL"
      @closed="downloadUrlList = []"
      @result="onResult"
    >
      <image-container v-if="downloadUrlList.length" asset-name="success" class="mb-6">
        <p>Hvis filerne ikke automatisk blev downloadet i din browser, kan du trykke på et eller flere links herunder</p>
        <a
          v-for="(url, index) in downloadUrlList"
          :key="index"
          :href="url"
          _target="blank"
          class="underline text-blue-500 cursor-pointer my-2"
        >Download fil nr. {{index + 1}}</a>
      </image-container>
    </job-poller>

    <image-container v-else asset-name="download" class="mb-6">
      <base-button
        class="mt-2"
        color="indigo"
        :disabled="!!generateExternalExportJobId"
        @click="startExport"
      >Eksporter</base-button>
    </image-container>
  </secondary-nav-bar-content>
</template>

<script>
import SecondaryNavBarContent from '@/layout/SecondaryNavBarContent.vue';
import JobPoller from '@/components/JobPoller.vue';
import ImageContainer from '@/components/ImageContainer.vue';

import { mapActions, mapGetters } from 'vuex';
import * as jobTypes from '@/util/jobTypes';
import { EXPORT_TASKS } from '@/store/actions.type';

export default {
  name: 'ExternalExport',
  components: {
    SecondaryNavBarContent,
    JobPoller,
    ImageContainer,
  },
  data() {
    return {
      jobTypes,
      downloadUrlList: [],
    };
  },
  computed: {
    ...mapGetters('jobs', ['generateExternalExportJobId']),
  },
  methods: {
    ...mapActions('tasks', {
      exportTasks: EXPORT_TASKS,
    }),
    async startExport() {
      this.$ntf.info('Begynder eksport...');
      try {
        await this.exportTasks();
      } catch (error) {
        this.$unhandledError(error);
      }
    },
    onResult(downloadUrlList) {
      this.$ntf.success('Din eksport er klar');
      this.downloadUrlList = downloadUrlList;

      downloadUrlList.forEach((downloadUrl) => {
        window.open(downloadUrl, '_blank');
      });
    },
  },
};
</script>
