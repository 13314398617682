<template>
  <base-alert
    title="Fejl"
    description="Du har ikke adgang til dette link eller det findes ikke"
    type="error"
    class="mt-8 ml-8 max-w-5xl"
  ></base-alert>
</template>

<script>
export default {
  name: 'ForbiddenExternalAccessView',
};
</script>
