<template>
  <div class="p-8 text-red-600 text-xl">
    Du har ikke tilladelse til at se denne side
  </div>
</template>

<script>
export default {
  name: 'ForbiddenView',
};
</script>
