<template>
  <div class="px-4 mt-6 sm:px-6 lg:px-8 bg-gray-100">
    <h1 class="text-2xl font-extrabold text-gray-900">Opgaver</h1>

    <task-table></task-table>
  </div>
</template>

<script>
import TaskTable from '@/components/table/TaskTable';

export default {
  name: 'TaskList',
  components: {
    TaskTable,
  },
};
</script>

<style>

</style>
